import type { AppMenuItem } from '@camino-solutions/core/app/ui/app-menu';
import { environment } from '@camino-solutions/core/environment';
import {
  inject as angularInject,
  Injector,
  type ProviderToken,
  runInInjectionContext,
} from '@angular/core';
import {
  pageFlowGoToByFlowId,
  pageFlowTokenRoutePathGenerator,
} from '@camino-solutions/core/page-flow/router';
import { Router } from '@angular/router';
import {
  CoreInviteCompanyRule,
  PlatformProjectsCreateProjectRule,
  SubscriptionsListLicencesRule,
  SubscriptionsListOwnRule,
} from '@camino-solutions/core/access-rule';
import { AppState } from '@camino-solutions/core/app/state';
import { Store } from '@ngxs/store';
import { isNil } from '@camino-solutions/utils/typeguard';
import { RouteInfos } from '@camino-solutions/core/router';

export const menu: (injector: Injector) => readonly AppMenuItem[] = (injector: Injector) => {
  const inject = <T>(token: ProviderToken<T>): T =>
    runInInjectionContext(injector, () => angularInject(token));

  return Object.freeze([
    {
      items: [
        {
          label: 'Irányítópult',
          icon: 'pi pi-chart-line',
          items: [
            {
              label: 'Diagramok',
              icon: 'pi pi-chart-bar',
              routerLink: ['/'],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'Cég',
          icon: 'pi pi-building',
          accessRule: CoreInviteCompanyRule.instance,
          items: [
            {
              label: 'Új',
              icon: 'pi pi-plus',
              command: () => pageFlowGoToByFlowId('company', inject(Router)),
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'Projekt',
          icon: 'pi pi-briefcase',
          accessRule: PlatformProjectsCreateProjectRule.instance,
          items: [
            ...inject(Store)
              .selectSnapshot(AppState.menuOperations)
              .map(projectOperation => {
                return {
                  label: projectOperation.menuItem,
                  icon: 'pi pi-plus',
                  command: () => {
                    const flow = projectOperation.flow;
                    if (isNil(flow) === false) {
                      inject(Router).navigate([pageFlowTokenRoutePathGenerator()], {
                        info: {
                          token: { type: 'string', value: flow.token },
                        } satisfies RouteInfos,
                      });
                    }
                    return null;
                  },
                };
              }),
          ],
        },
      ],
    },
    // {
    //   items: [
    //     {
    //       label: 'Dokumentum',
    //       icon: 'pi pi-file',
    //       items: [
    //         {
    //           label: 'Új',
    //           icon: 'pi pi-plus',
    //           command: () => pageFlowGoToByFlowId('document', inject(Router)),
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      // label: 'Dokumentum',
      items: [
        {
          label: 'Előfizetés',
          icon: 'pi pi-wallet',
          items: [
            {
              label: 'Fix',
              icon: 'pi pi-minus',
              accessRule: SubscriptionsListOwnRule.instance,
              routerLink: ['/subscriptions/fixed'],
            },
            {
              label: 'Rugalmas',
              icon: 'pi pi-wave-pulse',
              accessRule: SubscriptionsListOwnRule.instance,
              routerLink: ['/subscriptions/flexible'],
            },
            {
              label: 'Engedélyek',
              icon: 'pi pi-shield',
              accessRule: SubscriptionsListLicencesRule.instance,
              routerLink: ['/subscriptions/licences'],
            },
            // {
            //   label: 'Engedély kimutatások',
            //   icon: 'pi pi-chart-bar',
            //   routerLink: ['/subscriptions/licences/reports'],
            // },
          ],
        },
      ],
    },
    {
      // label: 'Dokumentum',
      items: [
        {
          label: 'Sablonok',
          icon: 'pi pi-file',
          items: [
            {
              label: 'Űrlapok',
              icon: 'pi pi-table',
              // accessRule: SubscriptionsListOwnRule.instance,
              routerLink: ['/templates/forms'],
            },
            {
              label: 'Dokumentumok',
              icon: 'pi pi-folder-open',
              // accessRule: SubscriptionsListOwnRule.instance,
              routerLink: ['/templates/documents'],
            },
          ],
        },
      ],
    },
    ...(['development', 'local-docker'].indexOf(environment.name) > -1
      ? [
          // {
          //   label: 'ttttt',
          //   items: [
          //     {
          //       label: 'ttttt',
          //       command: () => {
          //         const router = inject(Router);
          //         const queryParams = {
          //           options: KeyValueStore.create(addMinutes(new Date(), 5)).add(
          //             'EstimationImportContext',
          //             new EstimationImportContext({ tenderId: 'mockTenderId' }),
          //           ),
          //         };
          //         router.navigate(['/import'], { queryParams });
          //       },
          //     },
          //   ],
          // },
          /*           {
            label: 'PIVOT',
            items: [
              {
                label: 'PIVOT',
                icon: 'pi pi-download',
                routerLink: ['/pivot'],
              },
            ],
          },
          {
            label: 'Dani',
            items: [
              {
                label: 'Demo download',
                icon: 'pi pi-download',
                routerLink: ['/dani'],
              },
            ],
          }, */
          {
            label: 'Dani',
            items: [
              {
                label: 'Demo download',
                icon: 'pi pi-download',
                routerLink: ['/dani'],
              },
            ],
          },
        ]
      : []),
    // {
    //   label: 'Test',
    //   // accessRule: TestRule.instance,
    //   items: [
    //     {
    //       label: 'Test form',
    //       routerLink: ['/testform'],
    //       // accessRule: TestRule.instance,
    //     },
    //   ],
    // },
    // {
    //   label: 'Session panel',
    //   items: [
    //     {
    //       label: 'Debug',
    //       routerLink: ['/session-test-panel'],
    //     },
    //   ],
    // },
    /*     {
      label: 'Table test panel',
      items: [
        {
          label: 'Debug',
          routerLink: ['/table-test-panel'],
        },
      ],
    },
    {
      label: 'NEW Session panel',
      items: [
        {
          label: 'Panel',
          routerLink: ['/new-session-test-panel'],
        },
      ],
    }, */
  ]);
};
